import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/home",
    name: "home",
    component: () => import("@/views/framework.vue"),
    meta: { hide: true },
    children: [
      {
        path: "home",
        name: "首页",
        component: () => import("@/views/Home/index.vue"),
      },
      {
        path: "application",
        name: "体客应用",
        component: () => import("@/views/Application/index.vue"),
      },
      {
        path: "product/",
        name: "智慧运动视觉终端AITEEK+F1",
        component: () => import("@/views/Product/index.vue"),
      },
      {
        path: "product/1",
        name: "AI交互式运动触屏终端",
        component: () => import("@/views/Product/index1.vue"),
      },
      {
        path: "product/2",
        name: "智能动态体质评估一体机",
        component: () => import("@/views/Product/index2.vue"),
      },
      {
        path: "product/3",
        name: "AI智能动态慢病检测与运动干预一体机",
        component: () => import("@/views/Product/index3.vue"),
      },
      {
        path: "product/4",
        name: "脊柱AIS智能筛查系统",
        component: () => import("@/views/Product/index4.vue"),
      },
      {
        path: "product/5",
        name: "家庭智慧健康监测箱",
        component: () => import("@/views/Product/index5.vue"),
      },
      {
        path: "news",
        name: "新闻咨询",
        component: () => import("@/views/News/index.vue"),
      },
      {
        path: "introduce",
        name: "公司简介",
        component: () => import("@/views/Introduce/index.vue"),
      },
    ],
  },
  {
    path: "/appDownload",
    name: "APP下載",
    component: () => import("@/views/aiteek/appDownload.vue"),
    mode: history,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

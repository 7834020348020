import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

// 完整引入 element plus
// import ElementPlus from 'element-plus'
// import 'element-plus/dist/index.css'

// 引入字体图标
import '@/assets/iconfont/iconfont.css'

createApp(App).use(router).mount('#app')
